<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path class="st0" d="M172.1,42.9c-13.2-13.2-26.4-26.4-39.7-39.6c-0.8-0.8-1.8-1.4-2.8-1.9c-30.2,0-60.4,0-90.6,0  c-0.9,1.1-2.4,0.9-3.6,1.5C29.1,6.2,26,11.5,26,18.6c0,12.1,0,24.1,0,36.2c0,42.1,0,84.3,0,126.4c0,7.1,2.9,12.5,9.3,15.8  c2.4,1.2,5,1.7,7.6,1.7c38,0,76,0,114.1,0c9.9,0,17-7.1,17-17c0-44.7,0-89.4,0-134.1C174,45.7,173.4,44.2,172.1,42.9z M87.7,80.2  c0-3.2,1.6-4.8,4.7-4.8c5.1,0,10.1,0,15.2,0c3.1,0,4.7,1.7,4.7,4.8c0,5,0,10,0,15c0,3.2-1.6,4.8-4.9,4.8c-4.9,0-9.9,0-14.8,0  c-3.3,0-4.9-1.6-4.9-5c0-2.4,0-4.9,0-7.3C87.7,85.2,87.6,82.7,87.7,80.2z M50.7,87.7c0.2-7,5.3-12.1,12.2-12.3c4-0.1,8-0.1,11.9,0  c2.8,0,4.5,1.5,4.6,4.3c0.1,5.3,0.1,10.7,0,16c-0.1,2.8-1.8,4.3-4.6,4.3c-3.3,0-6.5,0-9.8,0c-3.1,0-6.3,0-9.4,0  c-3.3,0-4.9-1.6-4.9-4.8C50.6,92.7,50.6,90.2,50.7,87.7z M75.3,169.3c0,3-1.6,4.7-4.6,4.7c-2.6,0-5.3,0.1-7.9,0  c-6.7-0.3-11.7-5.2-12.1-11.8c-0.2-2.8-0.1-5.7,0-8.5c0.1-2.7,1.7-4.3,4.4-4.3c2.7-0.1,5.4,0,8.1,0c2.4,0,4.9,0,7.3,0  c3.2,0,4.8,1.6,4.8,4.7C75.4,159.1,75.4,164.2,75.3,169.3z M108.2,169.5c-0.1,2.9-1.6,4.5-4.6,4.5c-5.1,0-10.3,0-15.4,0  c-2.9,0-4.6-1.5-4.7-4.4c-0.1-5.3-0.1-10.5,0-15.8c0.1-2.9,1.7-4.4,4.7-4.4c5.1,0,10.3,0,15.4,0c2.9,0,4.5,1.6,4.6,4.5  c0.1,2.6,0,5.3,0,7.9C108.2,164.3,108.2,166.9,108.2,169.5z M149.3,160.3c0,8.7-5,13.7-13.8,13.7c-4.7,0-9.4,0-14.1,0  c-3.5,0-5-1.5-5-5.1c0-7.4,0-14.8,0-22.2c0-4.3-1.4-5.6-5.6-5.6c-18.1,0-36.2,0-54.3,0c-4.5,0-5.8-1.3-5.8-5.8c0-7.3,0-14.5,0-21.8  c0-3.8,1.5-5.3,5.4-5.3c14.6,0,29.3,0,43.9,0c14.5,0,29,0,43.5,0c4.5,0,5.8,1.3,5.8,5.9C149.3,129.5,149.3,144.9,149.3,160.3z   M149.3,95.4c-0.1,2.9-1.7,4.5-4.6,4.6c-3.3,0.1-6.5,0-9.8,0c-3.1,0-6.3,0-9.4,0c-3.3,0-4.9-1.6-5-5c0-4.9,0-9.8,0-14.6  c0-3.5,1.6-5,5.1-5.1c3.7,0,7.3,0,11,0c7.5,0.1,12.7,5.3,12.8,12.8C149.4,90.5,149.4,93,149.3,95.4z"/>`
  </icon-base>
</template>

<script>
export default {
  name: 'iconSIM',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

